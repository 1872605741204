<template>
  <v-app>
    <navigationDrawer
      v-if="$route.name != 'Login' && $route.name != 'PagoOnline' && $route.name != 'GenerarLink'"
    />
    <v-main :style="imagen">
      <v-container fluid>
        <v-scroll-x-transition hide-on-leave>
          <router-view/>
        </v-scroll-x-transition>
      </v-container>
    </v-main>
    <!-- Modal Carga -->
    <modalCarga />
    <!-- SnackBar Aviso -->
    <snackBarAviso />
    <!-- Modal Aviso -->
    <modalAviso />
  </v-app>
</template>

<script>
import modalCarga from './components/util/modalCarga.vue'
import snackBarAviso from './components/util/snackBarAviso.vue'
import { mapActions } from 'vuex'
import router from './router'
import navigationDrawer from './components/util/navigationDrawer.vue'
import modalAviso from './components/generales/modalAviso.vue'
export default {
  name: 'App',

  data: () => ({
    intentosLogin: 0,
    loginPeticion: {}
  }),
  components: {
    modalCarga,
    snackBarAviso,
    navigationDrawer,
    modalAviso
  },
  created(){

  },
  computed: {
    imagen (){
      return [ this.$route.name === 'Login' ? {
        'background-image' : `url(${require('../public/img/Login.webp')})`,
        'background-position' : 'center',
        'background-repeat' : 'no-repeat',
        'height' : '500px',
        'background-size' : 'cover'
      } : {
        'height' : '100%'
      } ]
    },
  },
  methods: {
    ...mapActions(['logOutFirebase']),
    async initProject(){
      // si entro al generador de zeldas lo dejo pasar, adelante mi rey
      if (this.$route.name == 'GenerarLink') return

      // controlo que se encuentre logueado un usuario de Firebase siempre y cuando me encuentre en una ruta distinta del pago del cliente
      if (this.$route.name != 'PagoOnline'){
        let usuarioPeticion = await this.$store.dispatch('firebaseService/getCurrentUser')
        if (usuarioPeticion.resultado == 2){
          // no hay usuario logueado, redirecciono al Login siempre y cuando no me encuentre ya en el Login
          if (this.$route.path.toUpperCase() != '/LOGIN'){
            router.push({name: 'Login'})
          }
          return
        }
        if (usuarioPeticion.resultado == 0){
          // error al intentar obtener el usuario, redireccionar al Login
          if (this.$route.path.toUpperCase() != '/LOGIN'){
            router.push({name: 'Login'})
          }
          return
        }
      }
      // NO MAS LOGINS, AHORA USO APIKEY
      /*
      // si llego hasta aqui quiere decir que estoy logueado, pido token a la api de jarvis
      let tokenApiPeticion = await this.$store.dispatch('usuarios/executeLoginApi')
      if (tokenApiPeticion.resultado == 0){
        // ocurrio un problema al intentar loguearme a la API, deslogueo de firebase y redirecciono al Login
        await this.logOutFirebase({msj: 'No se pudo conectar con la API de Jarvis.', color: 'warning', activo: true})
      }
      */
      // controlo si me encuentro en el login. Si llegara ser así debo redireccionar al Home
      if (this.$route.path.toUpperCase() == '/LOGIN'){
        router.push({name: 'Inicio'})
      }
    },
    setModalAviso(){

    }
  },
  async beforeUpdate(){
    this.initProject()
  },
  async created () {
    await this.$store.dispatch('loadLocalStorage')
  },
  watch: {
    loginPeticion: async function(){
      if (this.loginPeticion.resultado == 0){
        this.intentosLogin = this.intentosLogin + 1
        if (parseInt(this.intentosLogin) <= 3){
          // NO MAS LOGINS, AHORA USO APIKEY
          /*
          this.loginPeticion = await this.$store.dispatch('usuarios/executeLoginApi')
          */
        }else{
          this.$store.state.modalCarga = false
          this.$store.state.msjSnackBar = 'No se pudo inicializar las variables necesarias para realizar el Cobro.'
          this.$store.state.colorSnackBar = 'error'
          this.$store.state.snackBarActivo = true
          setTimeout(() => [window.location.href = 'https://www.borigenbetzel.com.ar/'], 3000)
        }
      }
    }
  },
};
</script>

<style>
  #app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*color: #2c3e50;*/
}
.theme--dark.v-card {
  background-color: var(--v-secondary-base) !important;
  color: #fff;
}
.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: var(--v-secondary-base) !important;
  color: #fff;
}
.theme--dark.v-data-table {
    background-color: var(--v-secondary-base) !important;
    color: #fff;
}
.swal2-popup {
  font-family: Roboto, sans-serif !important;
}

.v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled>.v-input__control>.v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--single-line>.v-input__control>.v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled>.v-input__control>.v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--single-line>.v-input__control>.v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled>.v-input__control>.v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--single-line>.v-input__control>.v-input__slot {
  min-height: 32px !important;
  font-size: 14px ;
}
.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--box.v-select--chips--small.v-input--dense .v-select__selections, .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed.v-select--chips--small.v-input--dense .v-select__selections {
  min-height: 32px !important;
}
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner, .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-outer, .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-inner, .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-outer, .v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner, .v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-outer, .v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-inner, .v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-outer {
  margin-top: 4px !important;
}
.v-list, .v-list--dense .v-list-item .v-list-item__content, .v-list-item--dense .v-list-item__content {
  padding: 0px !important;
}
.v-list--dense .v-list-item, .v-list-item--dense {
  min-height: 30px !important;
}
.v-list-item__action {
  margin: 4px 8px 4px 0px !important;
}

.v-expansion-panel--active>.v-expansion-panel-header {
  min-height: 48px !important;
}

.v-text-field--outlined.v-input--dense .v-label {
  top: 8px !important; /* default: 10px */
}
.v-text-field--filled .v-text-field__prefix, .v-text-field--filled .v-text-field__suffix {
  max-height: 32px;
  margin-top: 0px !important;
}

.v-data-table>.v-data-table__wrapper>table>tbody>tr>td, .v-data-table>.v-data-table__wrapper>table>tbody>tr>th, .v-data-table>.v-data-table__wrapper>table>tfoot>tr>td, .v-data-table>.v-data-table__wrapper>table>tfoot>tr>th, .v-data-table>.v-data-table__wrapper>table>thead>tr>td, .v-data-table>.v-data-table__wrapper>table>thead>tr>th {
  padding: 0 8px !important;
}
.cebra tr:nth-of-type(even) { /* con even empieza el priero con color mas claro y con odd empieza el segundo */
  background-color: rgba(0, 0, 0, .1);
}

.v-data-table tbody tr.v-data-table__selected {
  background: var(--v-rowsel-base) !important;
}

.v-chip .v-icon {
  font-size: 18px !important;
}

input[type=tel] {
  text-align: right;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  text-align: right;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  text-align: right;
}

/* Estilos de los sweetalerts2 */
.swal2-popup {
  color: var(--v-swalText-base) !important;
  background: var(--v-swalBkg-base) !important;
}

/* boton confirmar */
.swal2-confirm {
  background: var(--v-success-base) !important;
}

/* boton cancelar */
.swal2-cancel {
  background: var(--v-error-base) !important;
}
</style>
