import store from '../store'
import { firebase } from '../firebase.js' // no tocar
import { getDatabase, ref, onValue, push, update, query, orderByChild, equalTo, get } from 'firebase/database'
import config from '../../config'
import moment from 'moment'
import {convertDecimals} from '../util/util'
//import firebase from 'firebase'
export default{
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async crearBotonPago({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let importe = datos.importe
          let descripcion = datos.descripcion
          let telefono = datos.telefono
          let correo = datos.correo
          let empresa = datos.empresa
          let fecha = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
          // obtengo el uid
          let uid = null
          if (datos.automatico == 1) {
            // si es automatico quiere decir q se genero desde hq
            uid = 'HQ'
          } else {
            let usuario = await store.dispatch('firebaseService/getCurrentUser')
            if (usuario.resultado == 0 || usuario.resultado == 2){
              return resolve({
                resultado: 2,
                msj: 'No se encontró usuario logueado.',
                link: null
              })
            }
            uid = usuario.usuario.uid
          }
          let newLink = {
            usuario_uid: uid,
            estado: 'PENDIENTE',
            descripcion: descripcion,
            reserva: datos.automatico == 1 ? datos.reserva : null,
            telefono: telefono,
            correo: correo,
            importe: importe,
            fecha_log: fecha,
            ingresa_financiacion: datos.ingresa_financiacion,
            ingresa_forma_pago: datos.ingresa_forma_pago,
            ingresa_tipo_tarjeta: datos.ingresa_tipo_tarjeta
          }
          // inserto un nuevo link
          let db = getDatabase()
          push(ref(db, 'boton_pago/' + empresa), newLink)
            .then((response) => {
              // capturo la key del nuevo nodo
              let key = response.key
              // asigno el nuevo link al nodo
              let link = datos.ipPublica + 'payment/' + empresa + '/' + key
              // actualizo el campo link en el nodo
              newLink.link = link
              update(ref(db, 'boton_pago/' + empresa + '/' + key), newLink)
              return resolve({
                resultado: 1,
                msj: 'OK',
                link: link
              })
            })
            .catch((error) => {
              return resolve({
                resultado: 0,
                msj: 'No se pudo crear el botón de pago: ' + error.message,
                link: null
              })
            })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo crearBotonPago: ' + error.message,
            link: null
          })
        }
      })
    },
    async inhabilitarBotonPago({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          // instanciar la BD
          let db = getDatabase()
          // obtengo el link de pago
          let botonPago = await this.dispatch('botonPago/getUnBotonPago', datos)
          if (botonPago.resultado == 0){
            return resolve({
              resultado: 0,
              msj: 'No se pudo obtener el Boton de Pago Original: ' + botonPago.msj
            })
          }
          // indico el nuevo estado
          botonPago.boton.estado = 'INHABILITADO'
          // actualizo el estado del boton de pago
          update(ref(db, 'boton_pago/' + datos.empresa + '/' + datos.key), botonPago.boton)
          return resolve({
            resultado: 1,
            msj: 'OK'
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo inhabilitarBotonPago: ' + error.message
          })
        }
      })
    },
    async getUnBotonPago({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let db = getDatabase()
          let data = []
          let botonPeticion = ref(db, 'boton_pago/' + datos.empresa + '/' + datos.key)
          onValue(botonPeticion, (snapshot) => {
            data = snapshot.val()
            return resolve({
              resultado: 1,
              msj: 'OK',
              boton: data
            })
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getUnBotonPago: ' + error.message,
            boton: null
          })
        }
      })
    },
    async completarBotonPago({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          // instanciar la BD
          let db = getDatabase()
          // obtengo los datos para el boton
          let fecha = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
          let estadosCobro = await store.dispatch('cobros/getEstadosCobro')
          if (estadosCobro.resultado == 0){
            return resolve({
              resultado: 0,
              msj: 'No se pudo obtener los estados de los cobros: ' + estadosCobro.msj
            })
          }
          let estado = ''
          for (let id in estadosCobro.estados){
            if (estadosCobro.estados[id].hasOwnProperty('estado_decidir') && estadosCobro.estados[id].estado_decidir != undefined && estadosCobro.estados[id].estado_decidir != null){
              if (estadosCobro.estados[id].estado_decidir.toUpperCase() == datos.boton.status.toUpperCase()){
                estado = estadosCobro.estados[id].descripcion
              }
            }
          }
          // armo la estructura del boton
          let data = {
            usuario_uid: datos.datosBoton.usuario_uid,
            estado: estado,
            descripcion: datos.datosBoton.descripcion,
            telefono: datos.datosBoton.telefono,
            importe: datos.datosBoton.importe,
            link: datos.datosBoton.link,
            fecha_log: datos.datosBoton.fecha_log,
            fecha_update_estado: fecha,
            lote: '0000',
            numero: datos.boton.numero,
            codigo_autorizacion: datos.boton.codigo_autorizacion,
            Ipg_TransaccionID: datos.boton.Ipg_TransaccionID,
            fecha_cupon: datos.boton.fecha_cupon,
            orderID: datos.boton.idpago,
            titular: datos.boton.titular,
            documento_numero: datos.boton.documento_numero,
            tarjeta_numero: datos.boton.tarjeta_numero.substring(datos.boton.tarjeta_numero.length - 4, datos.boton.tarjeta_numero.length),
            tarjeta_marca: datos.boton.forma_pago.TARJETA,
            financiacion: datos.boton.forma_pago.FINANCIACION,
            importe_cobrado: datos.boton.total,
            error_codigo: datos.boton.error_codigo,
            error_mensaje: datos.boton.error_mensaje,
            error_descripcion: datos.boton.error_descripcion,
            correo: datos.boton.correo,
            ingresa_financiacion: datos.boton.ingresa_financiacion,
            ingresa_tipo_tarjeta: datos.boton.ingresa_tipo_tarjeta,
            ingresa_forma_pago: datos.boton.ingresa_forma_pago
          }
          // paso a actualizar los datos del cobro en Firebase para el boton de pago
          update(ref(db, 'boton_pago/' + datos.empresa + '/' + datos.boton.idpago), data)
          return resolve({
            resultado: 1,
            msj: 'OK'
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo completarBotonPago: ' + error.message
          })
        }
      })
    },
    async completarAnulacion({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          // instanciar la BD
          let db = getDatabase()
          // obtengo el link de pago
          let botonPago = await this.dispatch('botonPago/getUnBotonPago', datos)
          if (botonPago.resultado == 0){
            return resolve({
              resultado: 0,
              msj: 'No se pudo obtener el Boton de Pago Original: ' + botonPago.msj
            })
          }
          // comparo los importes para ver si anularon el total del boton o solo parcialmente
          let anular = 1
          if (datos.parcial == 1){
            let importeBoton = convertDecimals(botonPago.boton.importe)
            if (importeBoton == datos.importe){
              // anularon el total
              anular = 1
            }else{
              anular = 0
            }
          }
          // indico el nuevo estado y la fecha log
          if (anular == 1){
            // marcar como anulado
            botonPago.boton.estado = 'ANULADO'
          }else{
            // actualizar el importe
            botonPago.boton.importe = convertDecimals(convertDecimals(botonPago.boton.importe) - convertDecimals(datos.importe))
            botonPago.boton.importe_cobrado = convertDecimals(convertDecimals(botonPago.boton.importe_cobrado) - convertDecimals(datos.importe))
          }
          botonPago.boton.fecha_log = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
          // actualizo el estado del boton de pago
          update(ref(db, 'boton_pago/' + datos.empresa + '/' + datos.key), botonPago.boton)
          return resolve({
            resultado: 1,
            msj: 'OK',
            anular: anular
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getUnBotonPago: ' + error.message,
            anular: null
          })
        }
      })
    },
    async getLinks({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          // pido los botones para la empresa
          let linksPeticion = await store.dispatch('cobros/getLinksPagoEmpresa', {empresa: datos.empresa, fecha_desde: datos.filtros.fecha_desde, fecha_hasta: datos.filtros.fecha_hasta})
          if (linksPeticion.resultado == 0){
            return resolve({
              resultado: 0,
              msj: linksPeticion.msj,
              links: null
            })
          }
          linksPeticion.links = Object.values(linksPeticion.links)
          // aplico los filtros
          if (parseInt(datos.filtros.importe) > 0){
            linksPeticion.links = linksPeticion.links.filter(element => parseFloat(element.importe) == parseFloat(datos.filtros.importe) || parseFloat(element.importe_cobrado) == parseFloat(datos.filtros.importe))
          }
          if (datos.filtros.estado.length > 0){
            linksPeticion.links = linksPeticion.links.filter(element => element.estado.toUpperCase() == datos.filtros.estado.toUpperCase())
          }
          if (datos.filtros.telefono.length > 0){
            linksPeticion.links = linksPeticion.links.filter(element => element.telefono == datos.filtros.telefono || element.telefono.toString().indexOf(datos.filtros.telefono.toString()) != -1)
          }
          return resolve({
            resultado: 1,
            msj: 'OK',
            links: linksPeticion.links
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getLinks: ' + error.message,
            links: null
          })
        }
      })
    },
    async checkApiPayment({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          // LLAMADA A LA API PARA ANULAR
          let estadoPeticion = await fetch(config.BASE_URL + `/cobros/checkApiPaymentBP?key_firebase=${datos.key_firebase}&tipo_pedido=${datos.tipo_pedido}&importe=${datos.importe}&tarjeta=${datos.tarjeta}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': config.APIKEY
            }
          })
          let estado = await estadoPeticion.json()
          estado.msj = estado.message
          return resolve(estado)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo checkApiPayment: ' + error.message
          })
        }
      })
    },
    async aprobarBotonPago({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          // instanciar la BD
          let db = getDatabase()
          // fecha
          let fecha = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
          // estado
          let estado = 'APROBADO'
          // obtengo el boton
          let botonPago = await this.dispatch('botonPago/getUnBotonPago', datos)
          if (botonPago.resultado == 0){
            return resolve({
              resultado: 0,
              msj: 'No se pudo obtener el Boton de Pago Original en aprobarBotonPago: ' + botonPago.msj
            })
          }
          // financiacion
          let financiacion
          if (botonPago.boton.hasOwnProperty('ingresa_forma_pago') && typeof botonPago.boton.ingresa_forma_pago == 'object' && botonPago.boton.ingresa_forma_pago.hasOwnProperty('FINANCIACION')){
            financiacion = botonPago.boton.ingresa_forma_pago.FINANCIACION
          }else{
            financiacion = datos.marca
          }
          // armo la estructura del boton
          let data = {
            usuario_uid: botonPago.boton.usuario_uid,
            estado: estado,
            descripcion: botonPago.boton.descripcion,
            telefono: botonPago.boton.telefono,
            importe: botonPago.boton.importe,
            link: botonPago.boton.link,
            fecha_log: botonPago.boton.fecha_log,
            fecha_update_estado: fecha,
            lote: datos.lote,
            numero: datos.numero,
            codigo_autorizacion: datos.codigo_autorizacion,
            Ipg_TransaccionID: datos.transaccion_id,
            fecha_cupon: datos.tdate,
            orderID: datos.key,
            titular: botonPago.boton.titular,
            documento_numero: datos.documento_numero,
            tarjeta_numero: datos.tarjeta_numero.substring(datos.tarjeta_numero.length - 4, datos.tarjeta_numero.length),
            tarjeta_marca: datos.marca,
            financiacion: financiacion,
            importe_cobrado: datos.importe,
            error_codigo: 0,
            error_mensaje: '',
            error_descripcion: '',
            correo: botonPago.boton.correo,
            ingresa_financiacion: botonPago.boton.ingresa_financiacion,
            ingresa_tipo_tarjeta: botonPago.boton.ingresa_tipo_tarjeta,
            ingresa_forma_pago: botonPago.boton.ingresa_forma_pago
          }
          // paso a actualizar los datos del cobro en Firebase para el boton de pago
          update(ref(db, 'boton_pago/' + datos.empresa + '/' + datos.key), data)
          return resolve({
            resultado: 1,
            msj: 'OK'
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo aprobarBotonPago: ' + error.message
          })
        }
      })
    },
    async updateBoton({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          // instanciar la BD
          let db = getDatabase()
          // fecha
          let fecha = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
          // estado
          let estado = ''
          if (datos.estado == 2) estado = 'APROBADO'
          else estado = 'ANULADO'
          // financiacion
          let financiacion
          if (typeof datos.ingresa_forma_pago == 'object' && datos.ingresa_forma_pago.hasOwnProperty('FINANCIACION')) financiacion = datos.ingresa_forma_pago.FINANCIACION
          else financiacion = datos.marca
          // armo la estructura del boton
          let data = {
            usuario_uid: datos.usuario_uid,
            estado: estado,
            descripcion: datos.descripcion,
            telefono: datos.telefono,
            importe: datos.importe,
            link: datos.link,
            fecha_log: datos.fecha_log,
            fecha_update_estado: fecha,
            lote: datos.lote,
            numero: datos.numero,
            codigo_autorizacion: datos.codigo_autorizacion,
            Ipg_TransaccionID: datos.transaccion_id,
            fecha_cupon: datos.tdate,
            orderID: datos.key_firebase,
            titular: datos.titular,
            documento_numero: datos.documento,
            tarjeta_numero: datos.nro_tarjeta,
            tarjeta_marca: datos.marca,
            financiacion: financiacion,
            importe_cobrado: datos.importe,
            error_codigo: 0,
            error_mensaje: '',
            error_descripcion: '',
            correo: datos.correo,
            ingresa_financiacion: datos.ingresa_financiacion,
            ingresa_tipo_tarjeta: datos.ingresa_tipo_tarjeta,
            ingresa_forma_pago: datos.ingresa_forma_pago
          }
          // paso a actualizar los datos del cobro en Firebase para el boton de pago
          update(ref(db, 'boton_pago/' + datos.empresa_codigo + '/' + datos.key_firebase), data)
          return resolve({
            resultado: 1,
            msj: 'OK'
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo updateBoton: ' + error.message
          })
        }
      })
    },
    async validarReservaAutomatica ({}, descripcion) {
      return await new Promise(async (resolve, reject) => {
        try {
          const db = getDatabase()
          const botonPagoRef = ref(db, 'boton_pago/5')
          const reservaQuery = query(botonPagoRef, orderByChild('reserva'), equalTo(descripcion.toString()))
          
          const snapshot = await get(reservaQuery)

          if (snapshot.exists()) {
            let link = ''
            snapshot.forEach((snapshot) => {
              link = snapshot.val().link
            })
            resolve({
              exito: 1,
              link: link
            })
          } else {
            resolve({
              exito: 1,
              link: null
            })
          }
        } catch (error) {
          console.log(error)
          resolve({
            exito: 0,
            link: null
          })
        }
      })
    },
    async getReservaWeb ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminRadaHQ/reserva?id=${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          })
          const data = await res.json()

          if (res.status == 200) {
            resolve({
              exito: true,
              ...data.data
            })
          } else {
            resolve({
              exito: false,
              message: data.message
            })
          }
          
        } catch (error) {
          resolve({
            exito: false,
            message: error.message
          })
        }
      })
    },
    async actualizarDatos({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          // instanciar la BD
          let db = getDatabase()
          // armo la estructura del boton
          let data = {
            importe: datos.importe,
            telefono: datos.telefono,
            correo: datos.correo
          }
          // paso a actualizar los datos del cobro en Firebase para el boton de pago
          update(ref(db, 'boton_pago/5/' + datos.key_firebase), data)
          return resolve({
            resultado: 1,
            msj: 'OK'
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo actualizarDatos: ' + error.message
          })
        }
      })
    }
  }
}